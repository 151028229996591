import { enableProdMode } from "@angular/core";

import { environment } from "./environments/environment";

import { AppComponent } from "./app/app.component";

import { bootstrapApplication } from "@angular/platform-browser";
import { applicationConfig } from "@app/app.config";

if (environment.production) {
  enableProdMode();
}

bootstrapApplication(AppComponent, applicationConfig).catch((err) =>
  console.error(err),
);
