import {
  HTTP_INTERCEPTORS,
  provideHttpClient,
  withInterceptorsFromDi,
} from "@angular/common/http";
import { importProvidersFrom } from "@angular/core";
import { Routes } from "@angular/router";
import { environment } from "@env/environment";
import { AuthGuard } from "./core/guards/auth.guard";
import { ProfileAuthGuard } from "./profile/guards/profile-auth.guard";
import { QuestionnaireAuthInterceptor } from "./profile/interceptors";
import { ProfileStoreModule } from "./profile/profile-store.module";
import {
  DiaryProductsRecipesService,
  DiaryProductsService,
  DiaryRecipesService,
  DiaryService,
  QuestionnaireService,
} from "./profile/services";
import { DiaryDetailsService } from "./profile/services/diary-details.service";
import { DiaryDietarySupplementService } from "./profile/services/diary-dietary-supplement.service";

const routes: Routes = [
  {
    path: "",
    pathMatch: "full",
    redirectTo: "dashboard",
  },
  {
    path: "auth",
    loadComponent: () =>
      import("@app/auth/auth.component").then((chunk) => chunk.AuthComponent),
    loadChildren: () =>
      import("@app/auth/auth.routes").then((chunk) => chunk.routes),
  },
  {
    path: "dashboard",
    data: { page: "dashboard" },
    canActivate: [AuthGuard],
    loadComponent: () =>
      import("@app/core/pages/dashboard/dashboard.component").then(
        (chunk) => chunk.DashboardComponent,
      ),
    loadChildren: () =>
      import("@app/core/pages/dashboard/dashboard.routes").then(
        (chunk) => chunk.routes,
      ),
  },
  {
    path: "async-redirect",
    loadComponent: () =>
      import("./async-redirect/async-redirect.component").then(
        (chunk) => chunk.AsyncRedirectComponent,
      ),
  },
  {
    path: "profile",
    data: { page: "profile" },
    loadComponent: () =>
      import("@app/profile/profile.component").then(
        (chunk) => chunk.ProfileComponent,
      ),
    loadChildren: () =>
      import("@app/profile/profile.routes").then((chunk) => chunk.routes),
    providers: [
      DiaryService,
      QuestionnaireService,
      DiaryRecipesService,
      DiaryProductsService,
      DiaryProductsRecipesService,
      DiaryDietarySupplementService,
      DiaryDetailsService,
      ProfileAuthGuard,
      importProvidersFrom(ProfileStoreModule),
      {
        provide: HTTP_INTERCEPTORS,
        useClass: QuestionnaireAuthInterceptor,
        multi: true,
      },
      provideHttpClient(withInterceptorsFromDi()),
    ],
  },
];

if (environment.production === false) {
  routes.push({
    path: "ui-kits",
    data: { page: "ui-kits" },
    loadComponent: () =>
      import("@app/shared/components/ui-kits/ui-kits.component").then(
        (chunk) => chunk.UiKitsComponent,
      ),
    loadChildren: () =>
      import("@app/shared/components/ui-kits/ui-kit-routes").then(
        (chunk) => chunk.routes,
      ),
  });
}

routes.push({
  path: "**",
  redirectTo: "dashboard",
});

export { routes };
