import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, throwError, timer } from "rxjs";
import { catchError, take } from "rxjs/operators";
import { ToastService } from "../services/toast.service";

@Injectable()
export class ErrorMessagesInterceptor implements HttpInterceptor {
  constructor(private toastr: ToastService) {}

  errorHandler(error: HttpErrorResponse): string {
    const errorMsg = error.error.message;
    const nestedErrors = error.error.errors;

    if (typeof errorMsg === "string") {
      this.toastr.error(errorMsg, {}, 7000);
    }

    if (nestedErrors) {
      this.toastr.multipleErrorToast(nestedErrors, {}, 7000);
    }

    return errorMsg;
  }

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler,
  ): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        timer(0)
          .pipe(take(1))
          .subscribe(() => {
            if (error.error instanceof ErrorEvent) {
              // Клиентская ошибка
              this.errorHandler(error);
            } else {
              // Серверная ошибка
              this.errorHandler(error);
            }
          });

        return throwError(() => error.error);
      }),
    );
  }
}
